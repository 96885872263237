import React, { ReactElement, useState } from 'react'
import { CSSProperties } from 'react'
import RegisterYourInterestForm from './RegisterYourInterestForm'
import * as S from './RegisterYourInterestModal.styled'
import { AvailabilityDateTime, FormFieldProps } from '../../services/api/types'

export interface RegisterYourInterestModalProps {
  button?: ReactElement
  buttonStyle?: CSSProperties
  downloadFileOnSuccess?: boolean
  fileUrl?: string
  form?: {
    id: number
    slug: string
    fields: FormFieldProps[]
    submit_button_text: string
    title: string
    preamble: string
  }
  preferredLocation?: {
    state: string
    suburb: string
  }
  timeOut?: boolean
  availabilityDateTime?: Array<AvailabilityDateTime>
}

const RegisterYourInterestModal: React.FC<RegisterYourInterestModalProps> = ({
  button,
  buttonStyle,
  downloadFileOnSuccess,
  preferredLocation,
  fileUrl,
  form,
  availabilityDateTime
}) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const toggleModal = () => {
    setOpen(!isOpen)
  }

  return (
    <>
      <S.ButtonWrap onClick={toggleModal} style={buttonStyle}>
        {button}
      </S.ButtonWrap>
      {isOpen && (
        <S.OverlayModal
          backgroundProps={{ style: { alignItems: 'flex-start' } }}
          isOpen={isOpen}
        >
          {form && (
            <S.ModalInner>
              <RegisterYourInterestForm
                id={form.id}
                slug={form.slug}
                submitText={form.submit_button_text}
                fields={form.fields?.filter(
                  it => it?.field_block !== 'lead_mapping'
                )}
                title={form.title || 'Register your interest'}
                preamble={form.preamble || ''}
                onClose={toggleModal}
                downloadFileOnSuccess={downloadFileOnSuccess}
                preferredLocation={preferredLocation}
                fileUrl={fileUrl}
                availabilityDateTime={availabilityDateTime}
              />
            </S.ModalInner>
          )}
        </S.OverlayModal>
      )}
    </>
  )
}

export default RegisterYourInterestModal
